import React from 'react';
import { connect } from 'react-redux';
import styled from 'styled-components';
import { withPage } from '../Page';
import ActionCreator from '../ActionCreator';
import * as L from '../Utils/Lang';
import * as Widget from '../Components/Widget';

class ValidationPage extends React.Component {
  render() {
    let { navActions } = this.props;
  
    return (
      <Widget.Center extraCss="flex-grow: 1; min-height: 450px;">
        <Widget.Row
          justify='center'
          align='center'
          wrap='wrap'
          extraCss='max-width: 880px; width: 100%;'
        >
          <Image src='/images/validation/validate-success.png' />
          <Notification>
            <Text>{L.s('validation-success')}</Text>
            <Widget.BlueButton
              onClick={() => navActions.push("/")}
            >
             {L.s('back-home')}
            </Widget.BlueButton>
          </Notification>
        </Widget.Row>
      </Widget.Center>
    )
  }
}

const Image = styled.img`
  max-width: 100%;
  width: 440px;
  padding: 15px;
`;

const Notification = styled.div`
  max-width: 100%;
  width: 440px;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;

  @media screen  and (max-width: 880px) {
    align-items: center;
  }
`;

const Text = styled.div`
  margin-bottom: 15px;
  font-size: 20px;
  color: #3F8DCF;
`;

export default withPage(
  connect(
    (state, ownProps) => 
      null,
      ActionCreator
  )(ValidationPage));